import { render, staticRenderFns } from "./TheViewStartOnMobile.vue?vue&type=template&id=49ea069b&scoped=true&"
import script from "./TheViewStartOnMobile.vue?vue&type=script&lang=js&"
export * from "./TheViewStartOnMobile.vue?vue&type=script&lang=js&"
import style0 from "./TheViewStartOnMobile.vue?vue&type=style&index=0&id=49ea069b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ea069b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
