<template>
    <div class="container">
        <div class="mobile-content">
            <div class="card">
                <div class="card-content">
                    <v-card elevation="12">
                        <img
                          
                            :src="getApplicationLink"
                            alt="Logo Entelli"
                        />
                        <v-card-text class="message mt-1"
                            >Aplikacja dostępna tylko na komputerze z Windows,
                            iOS, Linux.</v-card-text
                        ></v-card
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['getApplicationLink']),
    },
}
</script>

<style lang="scss" scoped>
.container {
    height: 100vh;

    .mobile-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .card {
            height: 10vh;
            width: 90vw;
            margin: 20px;
            justify-content: center;
            text-align: center;

            .card-content {

                img {
                    padding-top: 10px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 45%;
                }

                .message {
                    font-weight: bold;
              
                }
            }
        }
    }
}
</style>
