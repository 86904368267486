export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
}

export function validationNameAndLastname(value) {
    let res =
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
            value
        )
    return res
}
