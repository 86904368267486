<template>
    <v-app>
        <div v-if="!isMobile(760)">
            <div class="login__container">
                <div class="center">
                    <div class="logo">
                        <img :src="getApplicationLink" alt="Logo Entelli" />
                    </div>
                    <div
                        :class="{ registerSlogan: stepper === 2 }"
                        class="slogan"
                    ></div>
                    <v-stepper
                        v-model="stepper"
                        class="navigation__list elevation-0"
                    >
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <transition name="fade">
                                    <div v-if="loadingAnimation" key="1">
                                        <form>
                                            <div class="login__description">
                                                <div class="text">
                                                    <span class="message-1"
                                                        >Zaloguj się</span
                                                    >
                                                    <div class="text-inner-2">
                                                        <v-icon class="icon"
                                                            >mdi-wrench</v-icon
                                                        >
                                                        <span class="message-2"
                                                            >Zleć serwis!</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="txt_field mt-4">
                                                <input
                                                    v-model="form.login"
                                                    :rules="[rules.required]"
                                                    type="text"
                                                    required
                                                    @keyup.enter="tryLogin"
                                                />
                                                <label>Login</label>
                                                <span />
                                            </div>
                                            <div class="error-message">
                                                {{ errors }}
                                            </div>

                                            <div class="txt_field">
                                                <input
                                                    v-model="form.password"
                                                    :label="$t('password')"
                                                    color="primary"
                                                    :append-icon="
                                                        show
                                                            ? 'mdi-eye-outline'
                                                            : 'mdi-eye-off-outline'
                                                    "
                                                    :type="
                                                        show
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    :rules="[rules.required]"
                                                    required
                                                    @click:append="show = !show"
                                                    @keyup.enter="tryLogin"
                                                />
                                                <span />
                                                <label>Hasło</label>
                                            </div>
                                            <div class="error-message">
                                                {{ errors }}
                                            </div>
                                        </form>

                                        <div class="progress-animation">
                                            <v-progress-circular
                                                v-if="!loading"
                                                :size="20"
                                                color="primary"
                                                indeterminate
                                            />
                                        </div>
                                        <div
                                            v-if="
                                                registerForm.apiMessageSuccess
                                            "
                                            class="api-message"
                                        >
                                            <div>Zaloguj się do aplikacji</div>
                                        </div>
                                        <div class="main-buttons">
                                            <v-btn
                                                class="buttons ma-2"
                                                data-cy="reset"
                                                @click="showRecovery = true"
                                            >
                                                {{ $t('login:reset') }}
                                            </v-btn>

                                            <v-btn
                                                class="buttons ma-2 buttons--main"
                                                data-cy="submit"
                                                :disabled="
                                                    form.login === '' ||
                                                    form.password === ''
                                                "
                                                @click="tryLogin"
                                            >
                                                {{ $t('login:log-in') }}
                                            </v-btn>
                                        </div>

                                        <div class="secondary-buttons">
                                            <span
                                                class="secondary-buttons__signUpText"
                                                @click="stepper = 2"
                                            >
                                                PIERWSZY RAZ?
                                                <span class="ml-3"
                                                    >Zarejestruj się</span
                                                ></span
                                            >
                                        </div>
                                    </div>
                                </transition>
                            </v-stepper-content>
                            <!-- SIGN UP STEPPER -->
                            <v-stepper-content step="2">
                                <transition name="fade">
                                    <div v-if="loadingAnimation" key="1">
                                        <form>
                                            <div class="register__description">
                                                <div class="text">
                                                    <span class="message-1"
                                                        >Rejestracja</span
                                                    >
                                                    <div
                                                        @click="
                                                            openRegisterDesc
                                                        "
                                                        class="text-inner-2"
                                                    >
                                                        <v-icon class="icon"
                                                            >mdi-account-question</v-icon
                                                        >

                                                        <span class="message-2"
                                                            >Jak się
                                                            zarejestrować?</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="txt_field mt-4">
                                                <input
                                                    v-model="registerForm.name"
                                                    :rules="[rules.required]"
                                                    type="text"
                                                    required
                                                    @change="registerValidation"
                                                />
                                                <label>{{
                                                    $t('contacts:name')
                                                }}</label>
                                                <span />
                                            </div>
                                            <div
                                                class="error-message mt-2 mb-3"
                                            >
                                                {{ registerForm.errors.name }}
                                            </div>
                                            <div class="txt_field mt-3">
                                                <input
                                                    v-model="
                                                        registerForm.lastName
                                                    "
                                                    :rules="[rules.required]"
                                                    type="text"
                                                    required
                                                    @change="registerValidation"
                                                />
                                                <label
                                                    ><label>{{
                                                        $t('contacts:lastname')
                                                    }}</label></label
                                                >
                                                <span />
                                            </div>
                                            <div class="error-message mt-2">
                                                {{
                                                    registerForm.errors.lastName
                                                }}
                                            </div>
                                            <div class="txt_field mt-3">
                                                <input
                                                    v-model="
                                                        registerForm.contact
                                                    "
                                                    :rules="[rules.required]"
                                                    type="text"
                                                    required
                                                    @change="registerValidation"
                                                />
                                                <label class="">{{
                                                    $t('login:contact')
                                                }}</label>
                                                <span />
                                            </div>
                                            <div class="error-message mt-2">
                                                {{
                                                    registerForm.errors.contact
                                                }}
                                            </div>
                                            <div class="txt_field mt-3">
                                                <input
                                                    v-model="registerForm.login"
                                                    :rules="[rules.required]"
                                                    type="text"
                                                    required
                                                    @change="registerValidation"
                                                />
                                                <label class="">{{
                                                    $t('login:login')
                                                }}</label>
                                                <span />
                                            </div>
                                            <div class="error-message mt-2">
                                                {{ registerForm.errors.login }}
                                            </div>
                                            <div class="txt_field mt-5">
                                                <input
                                                    v-model="
                                                        registerForm.password
                                                    "
                                                    :label="$t('password')"
                                                    color="primary"
                                                    :append-icon="
                                                        show
                                                            ? 'mdi-eye-outline'
                                                            : 'mdi-eye-off-outline'
                                                    "
                                                    :type="
                                                        show
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    :rules="[rules.required]"
                                                    required
                                                    @change="registerValidation"
                                                />
                                                <span />
                                                <label>{{
                                                    $t('password')
                                                }}</label>
                                            </div>
                                            <div class="error-message mt-2">
                                                {{
                                                    registerForm.errors.password
                                                }}
                                            </div>
                                            <div class="txt_field mt-5">
                                                <input
                                                    v-model="
                                                        registerForm.repeatPassword
                                                    "
                                                    :label="$t('password')"
                                                    color="primary"
                                                    :append-icon="
                                                        show
                                                            ? 'mdi-eye-outline'
                                                            : 'mdi-eye-off-outline'
                                                    "
                                                    :type="
                                                        show
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    :rules="[rules.required]"
                                                    required
                                                    @change="registerValidation"
                                                />
                                                <span />
                                                <label>{{
                                                    $t('global:repeatPassword')
                                                }}</label>
                                            </div>
                                            <div
                                                class="error-message mt-2 mb-3"
                                            >
                                                {{
                                                    registerForm.errors
                                                        .repeatPassword
                                                }}
                                            </div>
                                        </form>
                                        <transition name="fade">
                                            <div
                                                v-if="registerForm.apiMessage"
                                                class="error-message-container"
                                            >
                                                <div class="error-message mb-3">
                                                    {{
                                                        registerForm.apiMessage
                                                    }}
                                                </div>
                                            </div>
                                        </transition>
                                        <div
                                            class="secondary-buttons mt-2 mb-10"
                                        >
                                            <v-btn
                                                ref="registerBtn"
                                                class="secondary-button__btn--signup buttons buttons--main"
                                                :disabled="
                                                    !registerForm.validationInfo
                                                "
                                                @click="registerBtn"
                                            >
                                                Zarejestruj się
                                            </v-btn>

                                            <div class="mt-5 mb-10">
                                                <span
                                                    class="secondary-buttons__signUpText"
                                                    @click="
                                                        ;(stepper = 1),
                                                            clearRegisterForm()
                                                    "
                                                    >Wróć do logowania</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>

                    <transition name="fade">
                        <div v-if="!loadingAnimation" key="2">
                            <form>
                                <div class="greetings">
                                    <h2
                                        class="font-weight-light greetings-text"
                                    >
                                        {{ $t('login:greeting') }}
                                        {{ $get(user, 'name', '') }}
                                        {{ $get(user, 'lastName', '') }}
                                    </h2>
                                </div>
                            </form>
                        </div>
                    </transition>
                    <div class="footer">
                        <div class="footer__elements">
                            <a
                                class="login__panel__footer font-weight-light caption"
                                href="https://www.biuromax-balcer.pl"
                                target="_blank"
                                ><span style="white-space: nowrap"
                                    >Powered by</span
                                >
                            </a>
                            <div class="logo-footer">
                                <a href="https://entelli.pl/" target="_blank">
                                    <img
                                        src="./../../assets/img/global/repairs/entelli_logo.png"
                                        alt="Logo Entelli"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="version">Version 3.3</div>
            <v-menu
                v-model="showRecovery"
                min-width="400"
                content-class="recoveryModal"
                :close-on-content-click="false"
            >
                <v-card class="pa-7">
                    <h4 class="primary--text pb-4">
                        {{ $t('login:recoverLoginAndPassword') }}
                    </h4>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="reset.fields.nameAndlastName"
                                dense
                                :error-messages="reset.errors.nameAndlastName"
                                :label="$t('login:nameAndlastName')"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="reset.fields.email"
                                dense
                                :error-messages="reset.errors.email"
                                :label="$t('login:email')"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="reset.fields.phone"
                                dense
                                :label="$t('login:phone')"
                            /> </v-col
                        ><v-col cols="6">
                            <v-text-field
                                v-model="reset.fields.login"
                                dense
                                :label="$t('login:login')"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="reset.fields.contents"
                                dense
                                :label="$t('login:contents')"
                            />
                        </v-col>
                    </v-row>

                    <v-col cols="12" class="d-flex justify-end pa-0">
                        <v-btn
                            class="buttons buttons--add"
                            @click="resetPassword(reset)"
                        >
                            {{ $t('login:send') }}
                        </v-btn>
                    </v-col>
                </v-card>
            </v-menu>
            <div
                v-if="openRegisterDescToggle"
                min-width="400"
                class="registerDescModal"
            >
                <v-card style="background-color: #45433f" class="pa-7">
                    <h4 class="instruction--text pb-4">
                        Instrukcja rejestracji
                    </h4>
                    <div class="instruction--desc">
                        <ol>
                            <li>
                                Administrator - pracownik Twojej firmy/urzędu.
                                Posiada dostęp administracyjny do platformy.
                            </li>
                            <li>
                                Użytkownik - to Ty. Możesz założyć tutaj konto i
                                zlecać nam bezpośrednio usługi serwisu i mieć
                                nad nimi kontrolę. Jeśli nie posiadasz ID, zwróć
                                się do administratora lub do naszego Helpdesku:
                                <br />
                                tel: 52 3460165 <br />
                                email: info@biuromax-balcer.pl
                            </li>
                        </ol>
                    </div>
                </v-card>
            </div>
        </div>
        <div v-else>
            <TheViewStartOnMobile/>
        </div>
    </v-app>
</template>

<script>
import store from '../../store'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Rest from '../../plugins/Rest'
import {
    validateEmail,
    validationNameAndLastname,
} from '../../utils/validators'
import TheViewStartOnMobile from './TheViewStartOnMobile.vue'
export default {
    i18nOptions: { namespaces: ['login'] },
    data() {
        return {
            stepper: 1,
            showRecovery: false,
            form: {
                login: '',
                password: '',
            },
            registerForm: {
                name: '',
                lastName: '',
                login: '',
                password: '',
                repeatPassword: '',
                contact: '',
                errors: {},
                validationInfo: false,
                apiMessage: '',
                apiMessageSuccess: '',
            },
            reset: {
                fields: {
                    nameAndlastName: '',
                    email: '',
                    phone: '',
                    login: '',
                    contents: '',
                },
                errors: {
                    nameAndlastName: '',
                    email: '',
                },
            },
            rules: {
                required: value => !!value || this.$t('login:error'),
            },
            show: false,
            imgLogin: null,
            number: null,
            loading: true,
            loadingAnimation: true,
            user: '',
            errors: '',
            openRegisterDescToggle: false,
        }
    },
    computed: {
        ...mapGetters(['getLogoutModal', 'getApplicationLink']),
    },
    components: {
        TheViewStartOnMobile
    },

    methods: {
        ...mapActions([
            'startSessionTimer',
            'sendMail',
            'fetchApplicationLink',
        ]),
        ...mapMutations([
            'setPicture',
            'setSnackbars',
            'setUserSession',
            'setPicture',
            'setUserData',
        ]),

        isMobile(maxScreenSize) {
            if (screen.width <= maxScreenSize) {
                return true
            } else {
                return false
            }
        },
        closeConfirmModal() {
            this.getLogoutModal.open = false
        },

        // === CUSTOME IMAGE ON LEFT === //
        // getImgUrl() {
        //     this.number = Math.floor(Math.random() * 13) + 1
        //     this.imgLogin = require('@/assets/img/login/' +
        //         this.number +
        //         '.svg')
        // },
        validationMenu(reset) {
            for (const req in reset.errors) {
                if (reset.fields[req] == '') {
                    reset.errors[req] = `${this.$t('global:errorFields')}!`
                    this.setSnackbars({
                        type: 'error',
                    })
                } else {
                    this.reset.errors[req] = ''
                    return true
                }
            }
        },
        // === RESET PASSWORD === //
        resetPassword(reset) {
            let success = this.validationMenu(reset)

            if (success) {
                new this.Rest('POST', 'password')
                    .setBody({
                        details: {
                            name: this.reset.fields.nameAndlastName,
                            login: this.reset.fields.login,
                            phone: this.reset.fields.phone,
                            contents: this.reset.fields.contents,
                            mail: this.reset.fields.email,
                        },
                    })
                    .send()
                this.showRecovery = false
                this.setSnackbars({
                    type: 'success',
                    text: `${this.$t('global:sendToAdmin')}`,
                })
                this.reset.fields = {
                    nameAndlastName: '',
                    email: '',
                    phone: '',
                    login: '',
                    contents: '',
                }
            }
        },

        closeMenuModal() {
            this.showRecovery = false
            this.reset.fields = {
                nameAndlastName: '',
                email: '',
                phone: '',
                login: '',
                contents: '',
            }
        },

        // === LOGING === //
        async tryLogin() {
            this.errors = ''
            this.loading = !this.loading
            let response

            try {
                response = await new this.Rest('POST', 'user/login').send(
                    this.form
                )
                if (response.status == 200) {
                    this.user = response.data
                    this.loadingAnimation = !this.loadingAnimation
                    this.loading = !this.loading
                    this.setUserSession({ time: response.data.session.time })
                    this.setPicture(response?.data?.pictureBinary?.data)
                    this.setUserData(response.data)

                    window.sessionStorage.setItem(
                        'Token',
                        this.$get(response, 'headers.authorization', '')
                    )
                    await new Promise(resolve => setTimeout(resolve, 2000))
                    this.$router.push('dashboard')
                }
            } catch (err) {
                this.loading = !this.loading
                console.error(err.response)
                if (err.response) console.log(err.response.data.error)
                this.errors = `${this.$t(`login:${err.response.data.error}`)}!`
            }
        },

        registerValidation() {
            this.registerForm.errors = {
                name: '',
                login: '',
                lastName: '',
                password: '',
                repeatPassword: '',
                contact: '',
            }
            let value = true
            if (!this.registerForm.contact) {
                this.registerForm.errors.contact = 'Id jest wymagane'
                value = false
            }
            if (!this.registerForm.name) {
                this.registerForm.errors.name = 'Imię jest wymagane'
                value = false
            } else {
                const validation = validationNameAndLastname(
                    this.registerForm.name
                )

                if (!validation) {
                    this.registerForm.errors.name =
                        'Imię może składać się tylko z liter'
                    value = false
                }
            }
            if (!this.registerForm.lastName) {
                this.registerForm.errors.lastName = 'Nazwisko jest wymagane.'
                value = false
            } else {
                const validation = validationNameAndLastname(
                    this.registerForm.lastName
                )

                if (!validation) {
                    this.registerForm.errors.lastName =
                        'Nazwisko może składać się tylko z liter'
                    value = false
                }
            }
            if (!this.registerForm.login) {
                this.registerForm.errors.login = 'Email lub login jest wymagany'

                value = false
            } else {
                if (!validateEmail(this.registerForm.login)) {
                    this.registerForm.errors.login =
                        'Wymagany jest prawidłowy adres e-mail'
                }
            }
            if (!this.registerForm.password) {
                this.registerForm.errors.password = 'Hasło jest wymagane'
                value = false
            }
            if (
                !(
                    this.registerForm.repeatPassword ===
                    this.registerForm.password
                ) ||
                !this.registerForm.repeatPassword ||
                !this.registerForm.password
            ) {
                this.registerForm.errors.repeatPassword =
                    'Hasła muszą być takie same'
                value = false
            }

            this.registerForm.validationInfo = value
        },
        async registerBtn() {
            if (this.registerForm.validationInfo) {
                try {
                    const response = await new this.Rest(
                        'POST',
                        'user/register'
                    ).send(this.registerForm)
                    this.registerForm.apiMessageSuccess =
                        'Rejestracja przebiegła pomyślnie'
                    this.stepper = 1
                } catch (err) {
                    if (err.response.data.code === 11000) {
                        this.registerForm.apiMessage =
                            'Ten login jest już zajęty'
                        return
                    }
                    this.registerForm.apiMessage = 'Wystąpił błąd'
                }
            }
        },
        clearRegisterForm() {
            this.registerForm = {
                name: '',
                lastName: '',
                login: '',
                password: '',
                repeatPassword: '',
                errors: {},
                validationInfo: false,
                apiMessage: '',
            }
        },

        openRegisterDesc() {
            this.openRegisterDescToggle = !this.openRegisterDescToggle
        },
    },
    async beforeRouteEnter(to, from, next) {
        store.dispatch('fetchApplicationLink')
        if (to.path === '/login') {
            sessionStorage.removeItem('Token')
            await next()
            await new Rest('POST', 'user/logout').send()
        }
    },
}
</script>
<style lang="scss" scoped>
/* .session--text
    width: 350px
    text-align: center */

.version {
    position: absolute;
    font-size: 12px;
    z-index: 1;
    bottom: 0px;
    left: 10px;
    color: #b7b7b7;
}
.error-message-container {
    border-radius: 5px;
    background-color: rgba(198, 201, 207, 0.15);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 0.7rem;
    transition: 3.2s;
    padding: 2px;
}
.api-message {
    text-align: center;
    margin: -20px 50px 30px;
    color: green;
    font-size: 0.8rem;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(198, 201, 207, 0.15);
}

.login__container {
    height: 100vh;
    width: 100vw;
    background: url('./../../assets/img/login/login-background.jpg') no-repeat
        center center;
    background-size: cover;
}

.instruction--text {
    color: white;
}
.instruction--desc {
    font-size: 0.8rem;
    color: white;

    li {
        padding: 10px;
    }
}
.center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background: hsla(0,0%,100%,.7);
    border-radius: 5px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
    padding-top: 32px;
    min-height: 45rem;

    & form {
        .login__description {
            margin-bottom: 40px;
            .text {
                display: grid;
                grid-template-rows: 1fr;
                margin-left: 2px;
                text-align: left;

                .text-inner-2 {
                    margin-right: 14px;
                }
                .icon {
                    font-size: 16px;
                    padding-bottom: 2px;
                }
                .message-1 {
                    font-size: 1.5rem;
                }
                .message-2 {
                    padding-left: 7px;

                    font-size: 14px;
                    color: #adadad;
                }
            }
        }

        .register__description {
            margin-bottom: 0px;
            .text {
                display: grid;
                grid-template-rows: 1fr;
                margin-left: 2px;
                text-align: left;

                .text-inner-2 {
                    cursor: pointer;
                    color: #adadad;
                    margin-right: 14px;
                    transition: all 1.9s;
                }
                .text-inner-2:hover {
                    * {
                        color: black !important;
                    }
                }

                .icon {
                    font-size: 21px;
                    padding-bottom: 5px;
                }

                .message-1 {
                    font-size: 1.5rem;
                }

                .message-2 {
                    padding-left: 7px;
                    font-size: 14px;
                }
            }
        }

        padding: 0 25px;
        box-sizing: border-box;
        min-height: 100%;

        & .txt_field {
            position: relative;
            border-bottom: 2px solid #adadad;
            width: 100%;
            margin-bottom: -5px;
        }

        & .txt_field:nth-child(3) {
            margin-top: 12px;
        }

        & .txt_field input {
            width: 100%;
            height: 20px;
            font-size: 16px;
            border: none;
            background: none;
            outline: none;
            margin-top: 0px;
        }
    }

    & .logo {
        margin-top: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 60%;
        }
    }

    & .logo-footer {
        text-align: left;
        margin-left: 10px;

        img {
            max-width: 55%;
        }
    }

    & .slogan {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        margin-bottom: 130px;
        span {
            color: #ababab;
        }
    }

    & .registerSlogan {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;

        margin-bottom: 10px;
        span {
            color: #ababab;
        }
    }
    & .footer {
        position: absolute;
        text-align: center;
        bottom: 0%;
        left: 55%;
        transform: translate(-50%, -50%);
        & a,
        & a:visited,
        & a:link {
            color: #d1d1d1;
            text-decoration: none;
        }
        &__elements {
            display: flex;
        }
    }

    .apiMessage {
        text-align: center;
    }
}

.secondary-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .v-btn {
        min-width: 120px;
    }
    &__signUpText {
        font-size: 0.9rem;
        cursor: pointer;
        color: #ababab;
        text-transform: uppercase;
        padding: 1px;
        border-radius: 5px;
        transition: all 0.8s ease;
        color: #ba1f2b;

        &:hover,
        &:active {
            font-size: 0.75rem;
            background-color: rgba(166, 166, 166, 0.1);
            padding: 10px;
            transition: all 0.8s ease;
        }
    }
}
.main-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    margin-bottom: 3rem;
    button {
        height: 30px;
        border-radius: 7px;
        font-size: 10px;
        font-weight: 500;
        cursor: pointer;
        letter-spacing: 0.892857px;
        text-indent: 0.892857px;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);

        & :hover {
            border-color: linear-gradient(
                359.3deg,
                #ffcb00 -0.1%,
                #fc0 52.4%,
                #fec462 101.2%
            );
            transition: 0.5s;
        }
    }

    .btn {
        &-login {
            background: linear-gradient(
                359.3deg,
                #ffcb00 -0.1%,
                #fc0 52.4%,
                #fec462 101.2%
            );
        }
        &-reset {
            background-color: rgb(245, 245, 245);
        }
    }
}

.greetings {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    color: #66bb6a;
    width: max-content;
}
.progress-animation {
    text-align: center;
    justify-content: center;
    display: block;
    height: 2rem;
    margin-bottom: 1rem;
}

.txt_field label {
    position: absolute;
    top: 50%;
    left: 1px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: 0.5s;
    margin-top: -3px;
}

.txt_field span::before {
    content: '';
    position: absolute;
    top: 24px;
    left: 0;
    width: 0%;
    height: 1px;
    background: linear-gradient(
        359.3deg,
        #ffcb00 -0.1%,
        #fc0 52.4%,
        #fec462 101.2%
    );
    transition: 0.5s;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
    top: -5px;
    color: linear-gradient(359.3deg, #ffcb00 -0.1%, #fc0 52.4%, #fec462 101.2%);
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::before {
    width: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
    transition-timing-function: ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
@media only screen and (max-width: 1440px) {
    .center {
        width: 250px;
        min-height: 40rem;
        padding-top: 10px;
        max-height: 45rem;

        & form {
            .login__description {
                margin-bottom: 50px;
                .text {
                    .icon {
                        font-size: 11px;
                    }
                    .message-1 {
                        font-size: 0.9rem;
                    }
                    .message-2 {
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
    .secondary-buttons {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

        &__signUpText {
            display: flex;
            text-align: center;
            font-size: 0.8rem;
        }
    }
    .progress-animation {
        text-align: center;
        justify-content: center;
        display: block;
        height: 0rem;
        margin-bottom: 0rem;
    }

    .main-buttons {
        margin-top: 30px;
        justify-content: center;
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-flow: dense;
    }

    .center form {
        padding: 0 40px;
    }
    .buttons:nth-child(1) {
        order: 1;
    }
    .buttons:nth-child(2) {
        order: 2;
    }
    .center .slogan {
        margin-bottom: 10px;
    }
    .api-message {
        margin-top: 20px;
    }
    .error-message {
        font-size: 0.65rem;
    }
}
</style>
